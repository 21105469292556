<script setup>
const cList = ['bn', 'huobi', 'trust', 'im', 'bitpie', 'oe', 'tron', 'gate', 'ownbit', 'cc']

import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
</script>

<template>
  <div class="app-footer">
    <div class="container">
      <div class="c-list">
        <div v-for="c in cList" :key="c" class="c-item">
          <img :src="require(`@/assets/img/app-footer/${c}.png`)" />
        </div>
      </div>
      <div class="bar">
        <div class="logo">
          <img v-if="locale === 'cn'" src="@/assets/img/app-footer/logo.png" />
          <img v-else src="@/assets/img/app-footer/logo-en.png" class="en"/>
          <span class="copyright">© {{ t('bcg') }} 2022</span>
        </div>
        <!-- <div class="mail">
          <img src="@/assets/img/app-footer/mail.png" />
          <span class="contact">
            contact@168abc.com
          </span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-footer {
  background-color: #2F334E;
  overflow: hidden;
  margin: auto;
  color: #fff;
  .container {
    max-width: calc(1440px - (1.5rem * 2));
    padding: 4rem 1.5rem 3rem;
    margin: auto;
    @include mobile{
      padding: 1.5rem;
    }
  }
}
.c-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
  @include mobile {
    justify-content: flex-start;
  }
  .c-item {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    @include mobile {
      margin-left: auto;
      max-width: 21%;
      justify-content: flex-start;
      padding: 0;
      margin: 0.4rem;
      img {
        width: 4rem;
        object-fit: contain;
      }
    }
  }
}
.bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 2rem 2rem;
  flex-wrap: wrap;
  @include mobile{
    padding: 4rem 0 2rem;
    flex-flow: column;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile{
      order: 2;
      width: 100%;
      justify-content: space-between;
      img {
        height: 2rem;
        object-fit: contain;
        &.en {
          height: 1.4rem;
        }
      }
    }
    img {
      height: 3.5rem;
      object-fit: contain;
      // &.en {
      //   height: 1.4rem;
      // }
    }
    .copyright {
      margin-left: 1rem;
      font-size: 1.2rem;
      margin-top: 1rem;
      @include mobile{
        margin-top: 0;
        font-size: 0.8rem;
      }
    }
  }
  .mail {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile{
      order: 1;
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 2rem;
      margin-left: 1rem;
      img {
        height: 1.5rem;
        object-fit: contain;
      }
    }
    .contact {
      margin-left: 1rem;
      font-size: 1.2rem;
      @include mobile{
        font-size: 0.8rem;
      }
    }
  }
}
[data-lang=jp] {
  .logo {
    .copyright {
      @include mobile{
        font-size: 0.6rem;
      }
    }
  }
}
</style>