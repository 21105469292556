<template>
  <transition name="modal">
    <div class="app-msg-popup">
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { watch } from 'vue';

export default {
  name: 'AppMsgPopup',
  props: {
    popupName: String
  },
  setup(props, context) {
    const totalTimeCount = 2 // second
    const originTimeLeft = 0
    let timePassed = originTimeLeft;
    let timeRemain = totalTimeCount;
    let timerInterval = null;

    const start = () => {
      timerInterval = setInterval(() => {
        timePassed = timePassed += 1;
        timeRemain = totalTimeCount - timePassed;
        if (timeRemain === 0) timesUp();
      }, 1000);
    }
    const reset = () => {
      clearInterval(timerInterval);
      timePassed = originTimeLeft;
      timeRemain = totalTimeCount;
    }
    const timesUp = () => {
      context.emit('close', '')
      clearInterval(timerInterval);
    }
    watch(() => props.popupName, (value) => {
      if (!value) return reset()
      reset()
      start()
    })
  }
}
</script>

<style scoped lang="scss">
.app-msg-popup {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  .content {
    transform: translateY(-60%);
    background: no-repeat url('~@/assets/img/popup-bg.png');
    background-size: 100% 100%;
    padding: 0.8rem 1rem 1rem;
    font-size: 1rem;
    height: 3rem;
    color: #fff;
  }
}
</style>