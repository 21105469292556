<script setup>
import { ref, reactive, watch, defineProps } from 'vue';
import axios from 'axios';
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const props = defineProps({
  baseUrl: String,
})

const rankList = reactive({
  'win-list': {
    name: 'instant',
    mName: 'm-instant',
    trx: [],
    usdt: []
  },
  'rank-daily': {
    name: 'daily',
    mName: 'm-daily',
    trx: [],
    usdt: []
  },
  'rank-weekly': {
    name: 'weekly',
    mName: 'm-weekly',
    trx: [],
    usdt: []
  },
  'rank-monthly': {
    name: 'monthly',
    mName: 'm-monthly',
    trx: [],
    usdt: []
  }
})
const coinTypeList = ['trx', 'usdt']

const currentRankType = ref('win-list')
const currentCoinType = ref('trx')

const sliceString = (str) => {
  return str.length > 10 ? `${str.slice(0, 5)}....${str.slice(-5)}` : str
}
const keyTransfer = (key, rankType = false) => {
  const transferList = {
    "bh": 'bh',
    "adr": 'address',
    "sy": 'currency',
    "amt": 'profit',
    "ct": 'time'
  }
  if(rankType == 'win-list' && transferList[key] == 'profit') {
    return 'payback'
  }
  return transferList[key]
}

watch(() => currentCoinType.value, () => {
  APIRankList()
})
watch(() => currentRankType.value, () => {
  APIRankList()
})

const APIRankList = () => {
  let params = {
    symbol: currentCoinType.value.toUpperCase()
  }
  axios.get(`${props.baseUrl}/${currentRankType.value}`, { params })
  .then((res) => {
    if (res.data.code === 200) {
      rankList[currentRankType.value][currentCoinType.value] = res.data.data
    }
  })
  .catch((error) => {
    console.log(error);
  });
}
APIRankList()
</script>

<template>
  <div class="app-ranking" id="AppRanking">
    <div class="container">
      <div class="title-wrap">
        <img v-if="locale === 'kr'" src="@/assets/img/app-ranking/title-kr.png" class="title" />
        <img v-else-if="locale === 'jp'" src="@/assets/img/app-ranking/title-jp.png" class="title" />
        <img v-else-if="locale === 'cn'" src="@/assets/img/app-ranking/title-cn.png" class="title" />
        <img v-else-if="locale === 'tc'" src="@/assets/img/app-ranking/title-tc.png" class="title" />
        <img v-else src="@/assets/img/app-ranking/title-en.png" class="title" />
      </div>
      <div class="nav-bar">
        <div class="rank-list">
          <div 
            v-for="(rank, key) in rankList"
            :key="key"
            :class="['rank-item', { 'active': currentRankType === key}]"
            @click="currentRankType = key;"
          >
            <div class="name">{{ t(`app_rank.${rank.name}`) }}</div>
            <div class="m-name">{{ t(`app_rank.${rank.mName}`) }}</div>
          </div>
        </div>
        <div class="coin-type">
          <div class="coin-type-list">
            <div 
              v-for="coinType in coinTypeList" :key="coinType"
              :class="['coin-item', { active: currentCoinType === coinType}]"
              @click="currentCoinType = coinType"
            >
              {{ t(`app_rank.${coinType}_coin`) }}
            </div>
          </div>
        </div>
      </div>
      <div class="table-container">
        <table v-if="rankList[currentRankType][currentCoinType].length > 0" class="table-content">
          <!-- head -->
          <tr>
            <th v-if="currentRankType !== 'win-list'">
              {{ t(`app_rank.rank`) }}
            </th>
            <th v-for="th in Object.keys(rankList[currentRankType][currentCoinType][0])" :key="th">{{ t(`app_rank.${keyTransfer(th, currentRankType)}`) }}</th>
          </tr>
          <!-- body, idx 避免前三名圖片跳閃 -->
          <tr
            v-for="(item, idx) in rankList[currentRankType][currentCoinType]"
            :key="idx"
            class="table-container"
          >
            <td v-if="currentRankType !== 'win-list'" style="min-width: 3rem;">
              <div class="winner-img" v-if="idx >= 0 && idx <= 2">
                <img :src="require(`@/assets/img/app-ranking/no${idx + 1}.png`)" />
              </div>
              <div v-else>{{ idx + 1 }}</div>
            </td>
            <td 
              v-for="(td, idx) in Object.values(item)" :key="`${idx}-${currentCoinType}`"
            >
              <p v-if="['bh', 'adr'].includes(Object.keys(item)[idx])">
                {{ sliceString(td) }}
              </p>
              <p v-else-if="['amt'].includes(Object.keys(item)[idx])">
                ${{ Number(td).toFixed(2) }}
              </p>
              <p v-else-if="['ct'].includes(Object.keys(item)[idx])">
                <span class="time">{{ $dayjs.unix(td).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span class="m-time">{{ $dayjs.unix(td).format('MM-DD HH:mm') }}</span>
              </p>
              <p v-else>{{ td }}</p>
            </td>
          </tr>
        </table>
        <table v-else class="table-content">
          <tr>
            <th></th>
          </tr>
          <tr>
            <td>
              <div class="no-data">{{ t('loading') }}</div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-ranking {
  // height: calc(1695rem / 16);
  background: no-repeat top / contain url('~@/assets/img/app-ranking/ranking-bg.png');
  background-size: 100% auto;
  padding-top: 6rem;
  margin-top: -6rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  @include mobile {
    background: no-repeat top / contain url('~@/assets/img/app-ranking/m-ranking-bg.png');
    background-size: 100% auto;
    padding-top: 4rem;
    margin-top: -4rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .container {
    max-width: calc(1440px - (1.5rem * 2));
    padding: 0 1.5rem;
    margin: auto;
    color: var(--grey);
    font-size: 1.5rem;
    @include mobile{
      margin-top: -2rem;
      font-size: 0.6rem;
    }
  }
}
.title-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile{
    justify-content: center;
    margin: 1rem 0;
  }
  .title {
    margin: auto;
    object-fit: contain;
    @include mobile{
      height: 8rem;
      margin: auto;
      margin-bottom: -1.6rem;
    }
  }
}
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  @include mobile{
    flex-flow: column;
    padding: 0;
  }
  .rank-list {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: -3rem;
    margin-right: 6rem;
    @include mobile{
      justify-content: center;
      margin: 0;
      order: 2;
      width: 17rem;
      max-width: 100%;
    }
    .rank-item {
      cursor: pointer;
      margin: 0.5rem;
      font-size: 1.6rem;
      @include mobile{
        font-size: 1rem;
        margin: 0.3rem 0;
        flex: 0 1 25%;
        text-align: center;
      }
      .name {
        @include mobile{
          display: none;
        }
      }
      .m-name {
        display: none;
        @include mobile{
          display: block;
        }
      }
      &.active {
        color: #4a71f1;
      }
    }
  }
  .coin-type-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-radius: 1.5rem;
    box-shadow: inset -0.1rem 0.1rem 0.4rem #acacac,
            inset 0.1rem -0.1rem 0.4rem #ffffff;
    @include mobile{
      order: 1;
      height: 2rem;
      border-radius: 1rem;
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }
    & > * {
      flex: 1;
      width: 8rem;
      @include mobile{
        width: 6rem;
      }
    }
    .coin-item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
      height: 100%;
      border-radius: 1.5rem;
      @include mobile{
        border-radius: 1rem;
      }
      &.active {
        opacity: 1;
        color: #fff;
        box-shadow: inset 0 1px 6px 0 rgba(0, 0, 0, 0.2);
        background-image: linear-gradient(to bottom, #71b4ff, #3875ff);
      }
    }
  }
}
.table-container {
  margin-top: 2rem;
  width: 100%;
  box-shadow: 0 0.1rem 0.2rem #acacac;
  border-radius: 1.5rem;
  overflow: scroll;
  max-height: 60rem;
  @include mobile{
    margin-top: 1rem;
    border-radius: 0.6rem;
  } 
}
.table-content {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  tr {
    position: relative;
    &:first-child {
      z-index: 10;
      position: sticky;
      top: 0;
    }
    &:nth-of-type(2n) {
      background-color: #fff;
    }
    &:nth-of-type(2n-1) {
      background-color: #fafafa;
    }
    th {
      height: 3.6rem;
      line-height: 3.6rem;
      color: #fff;
      box-shadow: inset 0 -2px 0 0 rgba(133, 133, 133, 0.21);
      background-image: linear-gradient(to bottom, #71b4ff, #3875ff 136%);
      overflow: hidden;
      @include mobile{
        height: 2.4rem;
        line-height: 2.4rem;
        font-size: 0.7rem;
      } 
    }
    td {
      height: 5rem;
      line-height: 5rem;
      box-shadow: inset 0 -2px 0 0 rgba(133, 133, 133, 0.21);
      vertical-align: middle;
      @include mobile{
        font-size: 0.7rem;
      } 
    }
    .time {
      display: block;
      @include mobile{
        display: none;
      } 
    }
    .m-time {
      display: none;
      @include mobile{
        display: block;
      } 
    }
    .winner-img {
      height: 5rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 5rem;
        margin: -3rem;
        object-fit: contain;
      }
    }
  }
}
</style>