import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  warnHtmlInMessage: 'off',
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en', // 設定語言
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en', // 若選擇的語言缺少翻譯則退回的語言
  messages: loadLocaleMessages()
})

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
export default i18n