<script setup>
import { ref, defineProps } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
//import router from "@/router/index";
import { useRoute } from "vue-router";
import ImToken from "../views/tutorial/ImToken.vue"
import TrustWallet from "../views/tutorial/TrustWallet.vue"
import OwnBit from "../views/tutorial/OwnBit.vue"
import BitPie from "../views/tutorial/BitPie.vue"
import TronLink from "../views/tutorial/TronLink.vue"

const route = useRoute()

const props = defineProps({
  qrcode: String,
})

const currentProgress = ref('register') // init first

const progressList = {
  'register': {
    title: '注册数字币【去中心化】钱包',
    name: 'p1',
    official: [
      'https://token.im/',
      'https://www.tronlink.org/',
      'https://trustwallet.com/',
      'https://ownbit.io/tra/',
      'https://bitpie.com/'
    ],
    tutorial: [
      'ImToken',
      'TronLink',
      'TrustWallet',
      'OwnBit',
      'BitPie'
    ]
  },
  'buyCrypto': {
    title: '数字币交易所中购买TRX',
    name: 'p2',
    official: [
      'https://www.gate.io/cn',
      'https://www.huobi.com/zh-cn/',
      'https://www.binance.com/zh-CN',
      'https://www.okx.com/',
      'https://www.zb.com/cn/'
    ],
    tutorial: [
      'https://www.gate.io/cn/help/guide',
      'https://www.huobi.com/support/zh-cn/list/360000010372',
      'https://academy.binance.com/zh/articles/binance-beginner-s-guide',
      'https://www.okx.com/support/hc/zh-cn/sections/360000033031-%E6%96%B0%E6%89%8B%E5%BF%85%E8%AF%BB',
      'https://www.zb.com/help/guides/1'
    ]
  },
  'transfer': {
    title: '向本平台转账参与抽奖',
    name: 'p3'
  },
  'getBonus': {
    title: '中奖自动回款',
    name: 'p4'
  },
}
const mHandleImgPic = (name, active = '') => {
  if (active) active = '-active'
  switch (locale.value) {
    case 'kr':
      return require(`@/assets/img/app-progress/btn/kr/m-${name}${active}.png`)
    case 'jp':
      return require(`@/assets/img/app-progress/btn/jp/m-${name}${active}.png`)
    case 'cn':
      return require(`@/assets/img/app-progress/btn/cn/m-${name}${active}.png`)
    case 'tc':
      return require(`@/assets/img/app-progress/btn/tc/m-${name}${active}.png`)
    default:
      return require(`@/assets/img/app-progress/btn/en/m-${name}${active}.png`)
  }
}

const handleImgPic = (name, active = '') => {
  if (active) active = '-active'
  switch (locale.value) {
    case 'kr':
      return require(`@/assets/img/app-progress/btn/kr/${name}${active}.png`)
    case 'jp':
      return require(`@/assets/img/app-progress/btn/jp/${name}${active}.png`)
    case 'cn':
      return require(`@/assets/img/app-progress/btn/cn/${name}${active}.png`)
    case 'tc':
      return require(`@/assets/img/app-progress/btn/tc/${name}${active}.png`)
    default:
      return require(`@/assets/img/app-progress/btn/en/${name}${active}.png`)
  }
}

let showModal = ref(false);
const handleSpecialTutorial = (link) => {
  if (['ImToken', 'TrustWallet', 'OwnBit', 'BitPie', 'TronLink'].includes(link)) {
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    showModal.value = link;
  } else {
    // 外開
    if (route.query.cocos) {
      if(/ipad|iphone|iPod|iOS|mac/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.openBrowser.postMessage(link);
      }
      else {
        window.app.openURL(link)
      }
    } else {
      window.open(link, '_target');
    }
  }
}
const closeTutorial = () => {
  document.getElementsByTagName('body')[0].style.overflowY = 'auto'
  return false
}
</script>

<template>
  <div :class="['app-progress', locale ]" id="AppProgress">
    <div class="title-wrap">
      <div class="rocket">
        <img src="@/assets/img/app-progress/banner-img.png" />
      </div>
      <img v-if="locale === 'kr'" :src="require(`@/assets/img/app-progress/title-kr.png`)" class="title" />
      <img v-else-if="locale === 'jp'" :src="require(`@/assets/img/app-progress/title-jp.png`)" class="title" />
      <img v-else-if="locale === 'cn'" :src="require(`@/assets/img/app-progress/title-cn.png`)" class="title" />
      <img v-else-if="locale === 'tc'" :src="require(`@/assets/img/app-progress/title-tc.png`)" class="title" />
      <img v-else :src="require(`@/assets/img/app-progress/title-en.png`)" class="title" />
    </div>
    <div class="content-wrap">
      <div class="progress-list">
        <div
          v-for="(item, key) in progressList"
          :key="key"
          :class="['progress-item', { active: currentProgress === key }]"
          @click="currentProgress = key"
        >
          <div class="m-item">
            <img
              v-if="currentProgress !== key"
              class="m-item-img"
              :src="mHandleImgPic(item.name)" />
            <img
              v-else
              class="m-item-img"
              :src="mHandleImgPic(item.name, true)" />
            <img
              v-if="currentProgress === key"
              class="m-pointer"
              src="@/assets/img/app-progress/pointer.png" >
          </div>
          <div class="item">
            <img
              v-if="currentProgress !== key"
              class="item-img"
              :src="handleImgPic(item.name)" />
            <img
              v-else
              class="item-img"
              :src="handleImgPic(item.name, true)" />
            <img
              v-if="currentProgress === key"
              class="pointer"
              src="@/assets/img/app-progress/pointer.png" >
          </div>
        </div>
      </div>
      <section v-show="currentProgress === Object.keys(progressList)[0]" class="content">
          <picture v-if="locale === 'kr'">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/kr/p1-content.png" />
            <img src="@/assets/img/app-progress/kr/m-p1-content.png" />
          </picture>
          <picture v-else-if="locale === 'jp'">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/jp/p1-content.png" />
            <img src="@/assets/img/app-progress/jp/m-p1-content.png" />
          </picture>
          <picture v-else-if="locale === 'cn'">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/p1-content.png" />
            <img src="@/assets/img/app-progress/m-p1-content.png" />
          </picture>
          <picture v-else-if="locale === 'tc'">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/tc/p1-content.png" />
            <img src="@/assets/img/app-progress/tc/m-p1-content.png" />
          </picture>
          <picture v-else>
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/en/p1-content.png" />
            <img src="@/assets/img/app-progress/en/m-p1-content.png" />
          </picture>
          <div class="o-list">
            <div
              v-for="link in progressList['register'].official"
              :key="link"
              class="official"
              @click="handleSpecialTutorial(link)"
            />
          </div>
          <div class="t-list">
            <div
              v-for="link in progressList['register'].tutorial"
              :key="link"
              class="tutorial"
              @click="handleSpecialTutorial(link)"
            />
          </div>
      </section>
      <section v-show="currentProgress === Object.keys(progressList)[1]" class="content">
          <picture v-if="locale === 'kr'">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/kr/p2-content.png" />
            <img src="@/assets/img/app-progress/kr/m-p2-content.png" />
          </picture>
          <picture v-else-if="locale === 'jp'">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/jp/p2-content.png" />
            <img src="@/assets/img/app-progress/jp/m-p2-content.png" />
          </picture>
          <picture v-else-if="locale === 'cn'">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/p2-content.png" />
            <img src="@/assets/img/app-progress/m-p2-content.png" />
          </picture>
          <picture v-else-if="locale === 'tc'">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/tc/p2-content.png" />
            <img src="@/assets/img/app-progress/tc/m-p2-content.png" />
          </picture>
          <picture v-else>
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/en/p2-content.png" />
            <img src="@/assets/img/app-progress/en/m-p2-content.png" />
          </picture>
          <div class="o-list">
            <div
              v-for="link in progressList['buyCrypto'].official"
              :key="link"
              class="official"
              @click="handleSpecialTutorial(link)"
            />
          </div>
          <div class="t-list">
            <div
              v-for="link in progressList['buyCrypto'].tutorial"
              :key="link"
              class="tutorial"
              @click="handleSpecialTutorial(link)"
            />
          </div>
      </section>
      <section v-show="currentProgress === Object.keys(progressList)[2]" class="content">
          <div class="copy-wrap">
            <div class="container">
              <div class="qrcode-box">
                <QrcodeVue :value="props.qrcode" level="H" class="qrcode" />
              </div>
              <div class="copy-desc">
                <div class="c-title">
                  <p class="title">{{ t('platfrom_address') }}</p>
                  <p class="tag" id="copyQrcode-3">{{ props.qrcode }}</p>
                </div>
                <div class="c-btn" @click="$emit('copyEvent', 'copyQrcode-3')">{{ t('a_copy') }}</div>
              </div>
            </div>
          </div>
          <div class="transfer-content">
            <div class="p-title">{{ t('app_progress.transfer.title') }}</div>
            <p class="p-desc">{{ t('app_progress.transfer.desc_1') }}</p>
            <p class="p-desc">{{ t('app_progress.transfer.desc_2') }}</p>
          </div>
          <!-- <picture style="position: absolute; height: 100%; width: 100%; opacity: 0.3">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/p3-content.png" />
            <img src="@/assets/img/app-progress/m-p3-content.png" />
          </picture> -->
      </section>
      <section v-show="currentProgress === Object.keys(progressList)[3]" class="content">
        <div class="get-bonus-content get-bonus">
          <div class="p-title">{{ t('app_progress.get_bonus.title') }}</div>
          <p class="p-desc" v-html="t('app_progress.get_bonus.desc_1')"></p>
          <p class="p-desc">{{ t('app_progress.get_bonus.desc_2') }}</p>
          <img class="coin" src="@/assets/img/app-progress/coin.png" />
        </div>
          <!-- <picture style="position: absolute; height: 100%;width: 100%; z-index: 1; opacity: 1">
            <source media="(min-width: 992px)"
                    srcset="@/assets/img/app-progress/p4-content.png" />
            <img src="@/assets/img/app-progress/m-p4-content.png" />
          </picture> -->
      </section>
    </div>
  </div>
  <ImToken v-if="showModal === 'ImToken'" @close="showModal=closeTutorial()"></ImToken>
  <TrustWallet v-if="showModal === 'TrustWallet'" @close="showModal=closeTutorial()"></TrustWallet>
  <OwnBit v-if="showModal === 'OwnBit'" @close="showModal=closeTutorial()"></OwnBit>
  <BitPie v-if="showModal === 'BitPie'" @close="showModal=closeTutorial()"></BitPie>
  <TronLink v-if="showModal === 'TronLink'" @close="showModal=closeTutorial()"></TronLink>
</template>

<style lang="scss" scoped>
.app-progress {
  // height: calc(1315.7rem / 16);
  max-width: calc(1440px - (1.5rem * 2));
  padding: 0 1.5rem;
  margin: auto;
  padding-top: 7rem;
  margin-top: -7rem;
  @include mobile {
    padding-top: 3.5rem;
    margin-top: -3.5rem;
    padding-bottom: 4rem;
  }
}
.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
  @include mobile{
    justify-content: center;
    margin: 2rem 0;
  }
  .rocket {
    position: relative;
    width: 100%;
    height: 20rem;
    img {
      position: absolute;
      left: 2rem;
      top: 0;
      bottom: 0;
      transform: scale(1.6);
      height: 100%;
      object-fit: contain;
    }
    @include mobile{
      height: 6.8rem;
      left: 2.2rem;
      flex: 0 0 20%;
      img {
        left: 0;
      }
    }
  }
  .title {
    object-fit: contain;
    @include mobile{
      height: 8rem;
      margin-right: 0rem;
    }
  }
}
.content-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50rem;
  @include mobile{
    display: block;
    height: auto;
  }
  .progress-list {
    flex: 0 1 40%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 36rem;
    @include mobile{
      flex-flow: row;
      width: auto;
    }
    .progress-item {
      width: 100%;
      margin-bottom: 1rem;
      position: relative;
      cursor: pointer;
      .m-item {
        display: none;
        @include mobile{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: column;
          margin-bottom: 0;
          .m-item-img {
            margin: 0 0.4rem;
            height: 4.8rem;
            width: 4.5rem;
            object-fit: contain;
          }
          .m-pointer {
            height: 1.2rem;
            margin-top: 0.3rem;
          }
        }
      }
      .item {
        height: 10vw;
        max-height: 9rem;
        position: relative;
        .item-img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          object-fit: contain;
        }
        .pointer {
          position: absolute;
          top: 36%;
          right: -9%;
          height: 25%;
          transform: rotate(-90deg);
        }
        @include mobile{
          display: none;
        }
      }
    }
  }
  .content {
    flex: 0 1 60%;
    margin-left: 4rem;
    font-size: 1rem;
    position: relative;
    width: 100%;
    height: 46vw;
    max-height: 40rem;
     @include mobile{
      flex: 1;
      width: 21rem;
      max-width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 21rem;
    }
    img {
      width: 100%;
      object-fit: contain;
      @include mobile{ 
        height: 21rem;
        width: 21rem;
        min-width: 100%;
        object-fit: fill;
      }
    }
    .transfer-content {
      display: flex;
      flex-flow: column;
      align-items: center;
      @include mobile {
        height: 100%;
        width: 100%;
        background: no-repeat url('~@/assets/img/app-progress/m-p3-bg.png');
        background-size: 100% 100%;
      }
      .p-title {
        margin: 0 2rem;
        text-align: center;
        font-size: 1.6rem;
        color: #437BFC;
        font-weight: bold;
        padding-top: 0.4rem;
        padding-bottom: 8vw;
        @include mobile {
          font-size: 0.85rem;
          padding-top: 1.1rem;
          padding-bottom: 2.8rem
        }
      }
      .p-desc {
        text-align: center;
        font-size: 2.5vw;
        color: #67739F;
        font-weight: bold;
        line-height: 3.6vw;
        margin: 0 1vw;
        @include mobile {
          font-size: 0.95rem;
          margin: 0;
          line-height: 1.4rem;
        }
      }
      .coin {
        padding-top: 7.6vw;
        object-fit: contain;
        width: 87%;
        @include mobile {
          display: none;
        }
      }
    }
    .get-bonus-content {
      display: flex;
      flex-flow: column;
      align-items: center;
      @include mobile {
        height: 100%;
        background: no-repeat url('~@/assets/img/app-progress/m-p4-bg.png');
        background-size: 100% 100%;
        &.transfer {
          // background: no-repeat url('~@/assets/img/app-progress/m-p3-bg.png');
          // background-size: 100% 100%;
        }
      }
      .p-title {
        text-align: center;
        font-size: 1.6rem;
        color: #437BFC;
        font-weight: bold;
        padding-top: 0.4rem;
        padding-bottom: 8vw;
        @include mobile {
          font-size: 0.85rem;
          padding-top: 1.1rem;
          padding-bottom: 3.2rem
        }
      }
      .p-desc {
        text-align: center;
        font-size: 2.2rem;
        line-height: 3rem;
        color: #67739F;
        font-weight: bold;
        margin: 0 1vw;
        @include mobile {
          font-size: 0.95rem;
          margin: 0 2.5rem;
          line-height: 1.4rem;
        }
      }
      .coin {
        padding-top: 4rem;
        object-fit: contain;
        width: 87%;
        @include mobile {
          display: none;
        }
      }
    }
    // absolute 貼齊圖片
    .o-list {
      position: absolute;
      top: 10%;
      right: 36%;
      height: 100%;
      width: 28%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      @include mobile{
        top: 2rem;
        right: 28%;
        width: 6.8rem;
      }
      .official {
        height: 14%;
        width: 100%;
        margin-bottom: 0.5rem;
        @include mobile{
          height: 2.85rem;
          width: 100%;
          margin-bottom: 0.3rem;
        }
      }
    }
    .t-list {
      position: absolute;
      top: 10%;
      right: 2%;
      height: 100%;
      width: 28%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      @include mobile{
        top: 2rem;
        right: 4%;
        width: 4.6rem;
      }
      .tutorial {
        height: 14%;
        width: 100%;
        margin-bottom: 0.5rem;
        @include mobile{
          height: 2.85rem;
          width: 100%;
          margin-bottom: 0.3rem;
        }
      }
    }
  }
}
.copy-wrap {
  position: absolute;
  width: calc(100% - 6rem);
  bottom: 2rem;
  padding: 1.5rem 0;
  margin: 0 3rem;
  background: no-repeat bottom / contain url('~@/assets/img/app-address/box-bottom.png');
  @include mobile{
    width: 320px;
    max-width: 100%;
    bottom: 3rem;
    margin: auto;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
    padding: 0 2rem;
    width: 100%;
    @include mobile{
      max-width: 400px;
      padding: 0;
      align-items: center;
      height: 8rem;
      transform: scale(0.9);
    }
    .qrcode-box {
      background: no-repeat center / contain url('~@/assets/img/app-address/qrcode-bg.png');
      position: relative;
      font-size: 2.8rem;
      z-index: 0;
      flex: 0 1 40%;
      min-height: 8rem;
      height: 100%;
      @include mobile{
        flex: 0 0 8rem;
        // margin: 0 -0.8rem 0 0.8rem;
      }
      .qrcode {
        position: absolute;
        height: 80% !important;
        width: 80% !important;
        object-fit: contain;
        top: 0;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
        margin: auto;
        @include mobile{
          height: 100px !important;
          width: 100px !important;
        }
      }
    }
    .copy-desc {
      flex: 0 1 100%;
      color: var(--grey);
      padding: 1rem;
      @include mobile{
        padding: 0;
      }
      .c-title {
        text-align: center;
        .title {
          font-size: 1.35rem;
          margin: 0.4rem 0;
          white-space: nowrap;
          @include mobile{
            white-space: pre-wrap;
            font-size: 0.8rem;
            text-align: center;
          }
        }
        .tag {
          font-size: 0.8rem;
          font-weight: bold;
          &::selection {
            background: transparent;
          }
          @include mobile{
            text-align: center;
            font-size: 0.6rem;
            transform: scale(0.8);
          }
        }
      }
      .c-btn {
        cursor: pointer;
        height: 4rem;
        border-radius: 2rem;
        margin-top: 1.4rem;
        background-image: linear-gradient(to bottom, #70aef8, #4a71f1);
        line-height: 4rem;
        vertical-align: middle;
        text-align: center;
        color: #fff;
        font-size: 2.2rem;
        @include mobile{
          height: 2.4rem;
          line-height: 2.4rem;
          border-radius: 1.2rem;
          font-size: 1.2rem;
          margin: 1rem 1rem 0;
          min-width: 6rem;
        }
      }
    }
  }
}
// i18n
.en {
  .content-wrap .content {
    .transfer-content {
      .p-title {
        @include mobile {
          // margin: 0 2rem;
          // padding-bottom: 2rem;
        }
      }
      .p-desc {
        font-size: 1.8vw;
        line-height: 2.8vw;
        @include mobile {
          // margin: 0;
          font-size: 0.95rem;
          line-height: 1rem;
        }
      }
    }
    .get-bonus-content {
      .p-title {
        @include mobile {
          // padding-bottom: 2.5rem;
        }
      }
      .p-desc {
        font-size: 1.8rem;
        line-height: 2rem;
        @include mobile {
          font-size: 0.8rem;
          // margin: 0 1rem;
          line-height: 1.2rem;
        }
      }
    }
    .coin {
      // padding-top: 2.8vw;
    }
  }
}
.jp {
  .content-wrap .content {
    .transfer-content {
      .p-title {
        @include mobile {
          // margin: 0 3.8rem;
          // padding-bottom: 2rem;
        }
      }
      .p-desc {
        font-size: 2vw;
        line-height: 3vw;
        @include mobile {
          font-size: 0.8rem;
          line-height: 1.4rem;
          // margin: 0 1rem;
        }
      }
    }
    .get-bonus-content {
      .p-title {
        @include mobile {
          // padding-bottom: 2.5rem;
        }
      }
      .p-desc {
        font-size: 1.6rem;
        line-height: 2.4rem;
        @include mobile {
          font-size: 0.9rem;
          // margin: 0 1.5rem;
          line-height: 1.4rem;
        }
      }
    }
    .coin {
      // padding-top: 3.8vw;
    }
  }
}
.kr {
  .content-wrap .content {
    .transfer-content {
      .p-title {
        @include mobile {
          // margin: 0 3.8rem;
          // padding-bottom: 2rem;
        }
      }
      .p-desc {
        font-size: 2vw;
        line-height: 3vw;
        @include mobile {
          font-size: 0.95rem;
          line-height: 1.4rem;
          // margin: 0 1rem;
        }
      }
    }
    .get-bonus-content {
      .p-title {
        @include mobile {
          // padding-bottom: 2.5rem;
        }
      }
      .p-desc {
        font-size: 1.6rem;
        line-height: 2.4rem;
        @include mobile {
          font-size: 0.85rem;
          line-height: 1.4rem;
        }
      }
    }
    .coin {
      // padding-top: 3.8vw;
    }
  }
}
</style>
