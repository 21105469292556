<script setup>
import { defineProps } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const props = defineProps({
  qrcode: String,
})

const descList = [
  { 
    title: 'fair',
    desc: 'fair_desc'
  },
  { 
    title: 'open',
    desc: 'open_desc'
  },
  {
    title: 'secure',
    desc: 'secure_desc'
  }
]
</script>

<template>
  <div :class="['app-address', locale]" id="AppAddress">
    <div class="banner">
      <div class="title-wrap">
        <img v-if="locale === 'kr'" :src="require(`@/assets/img/app-address/title-kr.png`)" />
        <img v-else-if="locale === 'jp'" :src="require(`@/assets/img/app-address/title-jp.png`)" />
        <img v-else-if="locale === 'cn'" :src="require(`@/assets/img/app-address/title-cn.png`)" />
        <img v-else-if="locale === 'tc'" :src="require(`@/assets/img/app-address/title-tc.png`)" />
        <img v-else :src="require(`@/assets/img/app-address/title-en.png`)" />
      </div>
      <div class="copy-wrap">
        <div class="container">
          <div class="qrcode-box">
            <QrcodeVue :value="props.qrcode" level="H" class="qrcode" />
          </div>
          <div class="copy-desc">
            <div class="c-title">
              <p class="title">{{ t('must_decent') }}</p>
              <p class="tag" id="copyQrcode-1">{{ props.qrcode }}</p>
            </div>
            <div class="c-btn" @click="$emit('copyEvent', 'copyQrcode-1')">{{ t('a_copy') }}</div>
          </div>
        </div>
      </div>
      <div class="desc-wrap">
        <div class="desc-list">
          <div 
            v-for="descItem in descList"
            :key="descItem.title"
            class="desc-item"
          >
            <p class="d-title">{{ t(`app_address.${descItem.title}`) }}</p>
            <p class="d-desc">{{ t(`app_address.${descItem.desc}`) }}</p>
          </div>
        </div>
      </div>
      <div class="banner-img">
        <img src="@/assets/img/app-address/banner-img.png" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-address {
  // height: calc(961rem / 16);
  max-width: calc(1440px - (1.5rem * 2));
  padding: 8rem 1.5rem 3rem;
  margin: 0 auto 4rem;
  z-index: 100000000;
  @include mobile{
    padding: 2rem 1.5rem 0;
    padding-top: 6rem;
    margin: 0 auto 0.4rem;
  }
}
.banner {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1.3fr 1fr;
  grid-template-areas: 
    "title img"
    "copy img"
    "desc img"
  ;
  @include mobile{
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 100%;
    grid-template-areas: 
      "title"
      "img"
      "copy"
      "desc"
    ;
  }
}
.title-wrap {
  grid-area: title;
  width: 100%;
  img {
    width: 100%;
    margin: 2rem auto 0;
    height: 5rem;
    object-fit: contain;
    @include mobile{
      margin: auto;
      height: 2.5rem;
    }
  }
}
.copy-wrap {
  grid-area: copy;
  padding: 3rem 0 2rem;
  margin: 0 3rem;
  background: no-repeat bottom / contain url('~@/assets/img/app-address/box-bottom.png');
  @include mobile{
    padding: 1rem 0 1rem;
    margin: 1.5rem auto 0;
    width: 360px;
    max-width: 100%;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;
    padding: 2rem;
    width: 100%;
    @include mobile{
      max-width: 400px;
      padding: 0;
      align-items: center;
      height: 8rem;
      transform: scale(0.9);
    }
    .qrcode-box {
      background: no-repeat center / contain url('~@/assets/img/app-address/qrcode-bg.png');
      position: relative;
      font-size: 2.8rem;
      z-index: 0;
      flex: 0 0 12rem;
      height: 100%;
      @include mobile{
        flex: 0 0 7rem;
      }
      .qrcode {
        position: absolute;
        height: 132px !important;
        width: 132px !important;
        top: 0;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
        margin: auto;
        @include mobile{
          height: 92px !important;
          width: 92px !important;
        }
      }
    }
    .copy-desc {
      flex: 0 1 100%;
      color: var(--grey);
      padding: 1rem;
      @include mobile{
        padding: 0;
      }
      .c-title {
        text-align: center;
        .title {
          font-size: 1.35rem;
          margin: 0.4rem 0;
          white-space: pre-wrap;
          font-weight: bold;
          @include mobile{
            font-size: 0.84rem;
            text-align: center;
          }
        }
        .tag {
          font-size: 1rem;
          overflow:hidden;
          font-weight: bold;
          &::selection {
            background: transparent;
          }
          @include mobile{
            text-align: center;
            font-size: 0.6rem;
          }
        }
      }
      .c-btn {
        cursor: pointer;
        height: 4rem;
        border-radius: 2rem;
        margin-top: 1.4rem;
        background-image: linear-gradient(to bottom, #70aef8, #4a71f1);
        line-height: 4rem;
        vertical-align: middle;
        text-align: center;
        color: #fff;
        font-size: 2.2rem;
        @include mobile{
          height: 2.2rem;
          line-height: 2.2rem;
          border-radius: 1.1rem;
          font-size: 1.1rem;
          margin: 1rem 1.6rem 0;
        }
      }
    }
  }
}
.desc-wrap {
  grid-area: desc;
  height: 100px;
  color: var(--grey);
  padding-top: 3rem;
  @include mobile{
    padding-top: 1rem;
  }
  .desc-list {
    display: flex;
    justify-content: space-evenly;
    @include mobile{
      justify-content: center;
      & > * + * {
        margin-left: 0.5rem;
      }
    }
    .desc-item {
      display: flex;
      flex-flow: column;
      align-items: center;
      flex: 0 0 33%;
      .d-title {
        font-size: 2rem;
        margin-bottom: 1rem;
        font-weight: bold;
        @include mobile{
          margin-bottom: 0.5rem;
          font-size: 0.9rem;
        }
      }
      .d-desc {
        font-size: 1.3rem;
        text-align: center;
        @include mobile{
          font-size: 0.6rem;
        }
      }
    }
  }
}
.banner-img {
  grid-area: img;
  position: relative;
  width: 100%;
  img {
    position: absolute;
    left: -2rem;
    bottom: 2rem;
    transform: scale(1.4);
    height: 100%;
    object-fit: contain;
    z-index: -1;
  }
  @include mobile{
    height: 16rem;
    display: flex;
    justify-content: center;
    img {
      position: relative;
      left: 0;
      bottom: 0;
      height: 100%;
    }
  }
}

.en {
  .title-wrap img {
    @include mobile{
      margin: auto;
      height: 4.2rem;
    }
  }
}
</style>