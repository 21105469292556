<script setup>
import { reactive } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const qList = [
  {
    title: 'title',
    desc: 'anwser'
  },
  {
    title: 'title',
    desc: 'anwser'
  },
  {
    title: 'title',
    desc: 'anwser'
  },
  {
    title: 'title',
    desc: 'anwser'
  },
  {
    title: 'title',
    desc: 'anwser'
  },
  {
    title: 'title',
    desc: 'anwser'
  }
]

const currOpen = reactive([])
const handleToggle = (id) => {
  if (!currOpen.includes(id)) {
    currOpen.push(id)
  } else {
    const index = currOpen.indexOf(id);
    currOpen.splice(index, 1);
  }
}
</script>

<template>
  <div class="app-question" id="AppQuestion">
    <div class="title-wrap">
      <img v-if="locale === 'kr'" src="@/assets/img/app-question/title-kr.png" class="title" />
      <img v-else-if="locale === 'jp'" src="@/assets/img/app-question/title-jp.png" class="title" />
      <img v-else-if="locale === 'cn'" src="@/assets/img/app-question/title-cn.png" class="title" />
      <img v-else-if="locale === 'tc'" src="@/assets/img/app-question/title-tc.png" class="title" />
      <img v-else src="@/assets/img/app-question/title-en.png" class="title" />
    </div>
    <div class="q-list">
      <div 
        v-for="(item, idx) in qList" 
        :key="item.title" 
        :class="['q-item', { active: currOpen.includes(idx) }]"
        @click="handleToggle(idx)"
      >
        <div class="title-box">
          <span class="q-title">Q{{ idx + 1 }}</span>
          <div class="main-title">{{ t(`app_question.q${idx+1}.${item.title}`) }}</div>
          <span>
            <img class="triangle" src="@/assets/img/app-question/triangle.png" />
          </span>
        </div>
        <div v-show="currOpen.includes(idx)" class="desc">
          {{ t(`app_question.q${idx+1}.${item.desc}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-question {
  max-width: calc(1440px - (1.5rem * 2));
  padding: 0 1.5rem;
  margin: auto;
  color: var(--grey);
  font-size: 1.5rem;
  padding-bottom: 3rem;
  padding-top: 6rem;
  margin-top: -6rem;
  @include mobile{
    padding-top: 4rem;
    margin-top: -5.6rem;
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
.title-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4rem;
  @include mobile{
    justify-content: center;
    margin: 1rem 0;
  }
  .title {
    margin: auto;
    object-fit: contain;
    height: 20.7rem;
    @include mobile{
      height: 7.5rem;
      margin: auto;
      margin-bottom: -0.8rem;
    }
  }
}
.q-list {
  .q-item {
    cursor: pointer;
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    padding: 2rem;
    object-fit: contain;
    border-radius: 15px;
    box-shadow: inset 0 -3px 3px 0 rgba(107, 142, 235, 0.2), inset 0 3px 3px 0 rgba(107, 142, 235, 0.2);
    background-image: linear-gradient(to bottom, #fcfdff 33%, #e7edff 67%, #e7edff, #fafbff);
    @include mobile{
      margin-bottom: 1rem;
      padding: 1rem 0.8rem 1rem 0.5rem;
      border-radius: 0.4rem;
      background-image: linear-gradient(to bottom, #fcfdff, #e7edff, #e7edff, #fafbff);
    }
    &.active {
      box-shadow: inset 0 -3px 3px 0 rgba(107, 142, 235, 0.2), inset 0 3px 3px 0 rgba(107, 142, 235, 0.2);
      background-image: linear-gradient(to bottom, #fcfdff, #fff 50%, #fff, #fafbff);
      .title-box {
        padding-bottom: 2rem;
        @include mobile{
          padding-bottom: 0.8rem;
        }
        .triangle {
          transform: scaleY(1);
        }
      }
      .desc {
        padding-top: 2rem;
        border-top: solid 1.5px #e7e7e7;
        line-height: 2rem;
        transition: 3s;
        @include mobile{
          padding-top: 0.6rem;
          padding: 1rem 0.4rem 0;
          font-size: 0.8rem;
          line-height: 1.2rem;
        }
      }
    }
    .title-box {
      display: flex;
      justify-content: space-between;
      margin-right: 0;
      align-items: flex-start;
      .q-title {
        font-weight: bold;
        margin-right: 1.5rem;
        font-size: 2.2rem;
        line-height: 2rem;
        @include mobile{
          font-size: 1.2rem;
          line-height: 1.2rem;
          margin-right: 0.4rem;
        }
      }
      .main-title {
        margin-right: auto;
        font-size: 1.6rem;
        line-height: 2rem;
        @include mobile{
          font-size: 0.85rem;
          line-height: 1.2rem;
        }
      }
      .triangle {
        transform: scaleY(-1);
        margin-top: 0.5rem;
        @include mobile{
          margin-top: 0.34rem;
          margin-left: 0.5rem;
          height: 0.6rem;
          object-fit: contain;
        }
      }
    }
  }
}
</style>