<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const fixedList = [
  // {
  //   key: 'qr',
  //   name: '扫码转账'
  // },
  // {
  //   key: 'account',
  //   name: '账号转账'
  // },
  {
    key: 'service',
    name: 'to_kefu'
  }
]
</script>

<template>
  <div class="app-fixedbar">
    <div 
      v-for="item in fixedList" :key="item.key" 
      class="fixed-item"
      @click="$emit('openModal', item.key)"
    >
      <div class="icon">
        <img :src="require(`@/assets/img/app-fixedbar/${item.key}-icon.png`)" />
      </div>
      <div class="name">
        {{ t(item.name) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-fixedbar {
  position: fixed;
  z-index: 100;
  bottom: 5vh;
  right: 1rem;
  border: 1px solid #4a71f199;
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: #fff9;
  font-size: 1rem;
  @include mobile{
    font-size: 0.5rem;
    padding: 0.4rem;
    right: 0.6rem;
  }
}
.fixed-item {
  cursor: pointer;
  text-align: center;
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  .icon {
    height: 4rem;
    margin-bottom: 0.25rem;
    @include mobile{
      height: 3rem;
    }
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .name {
    color: #4a71f1;
    font-size: 0.6rem;
  }
}
</style>