<script setup>
import { ref, defineProps } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
import { useRoute } from "vue-router";
const route = useRoute()

const props = defineProps({
  kefuUrl: String
})

const currentAd = ref('data')
const advantageList = [
  {
    title: 'title',
    key: 'data',
    desc: [
      'desc_1',
      'desc_2',
      'desc_3'
    ]
  },
  {
    title: 'title',
    key: 'secure',
    desc: [
      'desc_1',
      'desc_2',
      'desc_3',
      'desc_4',
      'desc_5'
    ]
  },
  {
    title: 'title',
    key: 'speed',
    desc: [
      'desc_1',
      'desc_2',
      'desc_3',
      'desc_4'
    ]
  },
  {
    title: 'title',
    key: 'service',
    desc: [
      'desc_1',
      'desc_2'
    ],
    contact: {
      img: require('@/assets/img/app-advantage/service-icon.png')
    },
  },
]

const toKefu = () => {
  if (route.query.cocos) {
    if(/ipad|iphone|iPod|iOS|mac/i.test(navigator.userAgent)) {
      window.webkit.messageHandlers.openBrowser.postMessage(props.kefuUrl);
    }
    else {
      window.app.openURL(props.kefuUrl)
    }
  }
  else {
    window.open(props.kefuUrl, '_blank')
  }
}
</script>

<template>
  <div :class="['app-advantage', locale]" id="AppAdvantage">
    <div class="container">
      <div class="title-wrap">
        <img v-if="locale === 'kr'" src="@/assets/img/app-advantage/title-kr.png" class="title" />
        <img v-else-if="locale === 'jp'" src="@/assets/img/app-advantage/title-jp.png" class="title" />
        <img v-else-if="locale === 'cn'" src="@/assets/img/app-advantage/title-cn.png" class="title" />
        <img v-else-if="locale === 'tc'" src="@/assets/img/app-advantage/title-tc.png" class="title" />
        <img v-else src="@/assets/img/app-advantage/title-en.png" class="title" />
        <div class="lock">
          <img src="@/assets/img/app-advantage/banner-img.png" />
        </div>
      </div>
      <div class="m-advantage-list">
        <div class="ad-btn">
          <div
            v-for="(content, idx) in advantageList"
            :key="idx"
            class="ad-btn-item"
            @click="currentAd = content.key"
          >
            <img
              v-if="currentAd === content.key"
              :src="require(`@/assets/img/app-advantage/m-${content.key}-active.png`)" />
            <img
              v-else
              :src="require(`@/assets/img/app-advantage/m-${content.key}.png`)" />
          </div>
        </div>
        <div
          v-for="(content, idx) in advantageList"
          class="m-ad-list"
          :key="idx"
        >
          <div
            v-show="advantageList[idx].key === currentAd"  :class="['advantage-item',
              { 'two': [1].includes(idx) },
              { 'even': [1,3].includes(idx) },
              { 'four': [3].includes(idx) }
            ]"
          >
            <div class="title">{{ t(`app_advantage.c${idx+1}.${content.title}`) }}</div>
            <p
              v-for="el in content.desc"
              :key="el"
              class="desc"
            >
              {{ t(`app_advantage.c${idx+1}.${el}`) }}
            </p>
            <div v-show="false" v-if="content.contact" class="contact" @click="toKefu()">
              <img :src="content.contact.img" />
            </div>
          </div>
        </div>
      </div>
      <div class="advantage-list">
        <div
          v-for="(item, idx) in advantageList"
          :key="item.key"
          class="advantage-item"
        >
          <div class="title">{{ t(`app_advantage.c${idx+1}.${item.title}`) }}</div>
          <p
            v-for="el in item.desc"
            :key="el"
            class="desc"
          >
            {{ t(`app_advantage.c${idx+1}.${el}`) }}
          </p>
          <div v-show="false" v-if="item.contact" class="contact" @click="toKefu()">
            <img :src="item.contact.img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-advantage {
  // height: calc(1315.7rem / 16);
  background: no-repeat top / cover url('~@/assets/img/app-advantage/advantage-bg.png');
  background-size: 100% 100%;
  padding-bottom: 4rem;
  margin-bottom: -4rem;
  padding-top: 6rem;
  margin-top: -4rem;
  @include mobile {
    background: no-repeat top / cover url('~@/assets/img/app-advantage/m-advantage-bg.png');
    background-size: 100% 100%;
    padding-top: 3.2rem;
    margin-top: -3.2rem;
  }
  .container {
    max-width: calc(1440px - (1.5rem * 2));
    padding: 0 1.5rem;
    margin: auto;
  }
}
.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 0 2rem;
  margin-top: -2rem;
  @include mobile{
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
  }
  .lock {
    position: relative;
    width: 100%;
    height: 20rem;
    img {
      position: absolute;
      left: -7rem;
      top: -5rem;
      bottom: 0;
      transform: scale(1.8);
      height: 100%;
      object-fit: contain;
    }
    @include mobile{
      height: 5rem;
      width: 0;
      img {
        right: -4rem;
        top: -1rem;
      }
    }
  }
  .title {
    margin-left: -8rem;
    padding-top: 8rem;
    object-fit: contain;
    @include mobile{
      margin: 0;
      padding: 0;
      height: 7.6rem;
      margin-left: -5.5rem;
    }
  }
}
.advantage-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  @include mobile {
    display: none;
  }
  .advantage-item {
    flex: 0 0 25%;
    display: flex;
    flex-flow: column;
    height: 22rem;
    position: relative;
    padding: 2rem;
    margin-bottom: 1rem;
    background: no-repeat center / contain url('~@/assets/img/app-advantage/box-type-1.png');
    background-size: 100% 100%;
    &:nth-of-type(2n) {
      background: no-repeat center / contain url('~@/assets/img/app-advantage/box-type-2.png');
      background-size: 100% 100%;
    }
    @include mobile {
      flex: 0 0 22rem;
      height: 22rem;
      max-width: 100%;
    }
    .title {
      font-size: 2.4rem;
      text-align: center;
      margin-bottom: 1.5rem;
      @include mobile {
        font-size: 1.7rem;
        padding: 0.5rem 0;
      }
    }
    .desc {
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 0.6rem;
      @include mobile {
        font-size: 0.8rem;
        line-height: 1.5rem;
      }
    }
    .contact {
      margin: auto;
      height: 3rem;
      width: 100%;
      position: relative;
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.m-advantage-list {
  display: none;
  @include mobile {
    display: block;
    .ad-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.4rem;
      & > * + * {
        margin-left: 1.7rem;
      }
      .ad-btn-item {
        img {
          height: 3.6rem;
          width: 3.6rem;
          object-fit: contain;
        }
      }
    }
    .m-ad-list {
      color: #fff;
      margin: auto 0.2rem;
      .advantage-item {
        display: flex;
        flex-flow: column;
        height: 20rem;
        width: 21rem;
        max-width: 100%;
        position: relative;
        padding: 2rem;
        margin-bottom: 1rem;
        background: no-repeat url('~@/assets/img/app-advantage/box-type-1.png');
        background-size: 100% 100%;
        margin: auto;
        &.two {
          .desc {
            position: relative;
            margin-left: 0.8rem;
            margin-bottom: 0.2rem;
            &::before {
              content: ' • ';
              position: absolute;
              top: 0;
              bottom: 0;
              left: -0.8rem;
            }
          }
        }
        &.even {
          background: no-repeat url('~@/assets/img/app-advantage/box-type-2.png');
          background-size: 100% 100%;
        }
        &.four {
          text-align: center;
        }
        .title {
          text-align: center;
          margin-bottom: 1.2rem;
          font-size: 1.7rem;
          padding: 0.8rem 0;
        }
        .desc {
          margin-bottom: 0.8rem;
          font-size: 0.85rem;
          line-height: 1.2rem;
        }
        .contact {
          margin: auto;
          height: 3.5rem;
          width: 100%;
          position: relative;
          img {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
[data-lang=en], [data-lang=jp], [data-lang=kr] {
  .advantage-list .advantage-item {
    .title {
      font-size: 1.8rem;
      margin-bottom: 1.2rem;
    }
    .desc {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
  }
  .m-ad-list {
    .advantage-item {
      .title {
        margin: 0;
        padding: 0 0 1.2rem 0;
        white-space: nowrap;
      }
      .desc {
        margin-bottom: 0.6rem;
        font-size: 0.76rem;
        line-height: 1rem;
      }
      .contact {
        margin: auto;
        height: 3.5rem;
        width: 100%;
        position: relative;
        img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
.kr {
  .title-wrap {
    .lock {
      img {
        left: 0rem;
        @include mobile{
          left: -2rem;
        }
      }
    }
    .title {
      margin-left: 8rem;
      @include mobile{
        margin-left: -5.5rem;
      }
    }
  }
}
</style>
