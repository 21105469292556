<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useRoute } from "vue-router";
const route = useRoute()
import { useI18n } from "vue-i18n";
const { t } = useI18n();

import QrcodeVue from 'qrcode.vue'
import axios from 'axios';

import AppNavbar from '@/components/AppNavbar.vue'
import AppAddress from '@/components/AppAddress.vue'
import AppRules from '@/components/AppRules.vue'
import AppProgress from '@/components/AppProgress.vue'
import AppAdvantage from '@/components/AppAdvantage.vue'
import AppExchange from '@/components/AppExchange.vue'
import AppRanking from '@/components/AppRanking.vue'
import AppQuestion from '@/components/AppQuestion.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppFixedbar from '@/components/AppFixedbar.vue'
import AppModal from '@/components/AppModal.vue'
import AppMsgPopup from '@/components/AppMsgPopup.vue'

const navList = [
  {
    name: '转账地址',
    key: 'AppAddress'
  },
  {
    name: '中奖规则',
    key: 'AppRules',
  },
  {
    name: '参与流程',
    key: 'AppProgress',
  },
  {
    name: '平台优势',
    key: 'AppAdvantage',
  },
  {
    name: '数字币兑换',
    key: 'AppExchange',
  },
  {
    name: '排行榜',
    key: 'AppRanking',
  },
  {
    name: '常见问题',
    key: 'AppQuestion',
  },
]
// dev url
// const baseUrl = 'https://www.cjs969.com/web/v1'
const baseUrl = window.location.origin + '/web/v1'

// get qrcode
let qrcode = ref('加载中')
let qrcode_ex = ref('加载中') // 交换用的 QRCode
let kefu = ref('加载中')
let query = new URL(`https://1.com?${window.location.href.split('?')[1]}`).search.substring(1)
query = decodeURI(query)
let urlParamsObj = {}
let tempAry = []
query.split('&').forEach(param=>{
  tempAry = param.split('=')
  urlParamsObj[tempAry[0]] = tempAry[1]
})
let params = reactive({
  code: urlParamsObj.code
})
const APIConfig = (params) => {
  axios.get(`${baseUrl}/config`, { params })
  .then((res) => {
    console.log(res);
    qrcode.value = res.data.data.dp || ''
    qrcode_ex.value = res.data.data.ex || ''
    kefu.value = res.data.data.kefu || ''
  })
  .catch((error) => {
    console.log(error);
  });
}
APIConfig(params)

// showModal
let showModal = ref('')
const openModal = (key) => {
  if(key === 'service') {
    // 複製 v2 : 開啟客服連結
    if (kefu.value) {
      if (route.query.cocos) {
        if(/ipad|iphone|iPod|iOS|mac/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.openBrowser.postMessage(kefu.value);
        }
        else {
          window.app.openURL(kefu.value)
        }
      }
    } else {
      if (route.query.cocos) {
        window.webkit.messageHandlers.alert.postMessage('目前没有客服在线');
      } else {
        showModal.value = key
      }
    }
  } else {
    // 開啟舊有彈窗
    showModal.value = key
  }
}
function copyEvent(id) {
  var str = document.getElementById(id);
  window.getSelection().selectAllChildren(str);
  document.execCommand("Copy")
  popupOpen('copySuccess')
}

onMounted(() => {
  initObserver()
  observeSetting()
})

const appDOM = document.querySelector('#app')
let observer = reactive({})
let activePoint = ref('')
const goToPoint = (item) => {
  let top;
  appDOM.childNodes.forEach(e => {
    if (e.id === item) top = e.offsetTop
  })
  window.scrollTo({ top, behavior: 'smooth' });
}
const initObserver = () => {
  observer = new IntersectionObserver(entries => {
    const active = entries.filter(e => e.isIntersecting);
    if(active.length) activePoint.value = active[0].target?.id;
  }, { threshold: 0.2 })
}
const observeSetting = () => {
  appDOM.childNodes.forEach(e => {
    if (e.id) observer.observe(e)
  })
}

let popupName = ref('')
let popupOpen = (value) => {
  popupName.value = value
};
</script>

<template>
  <AppNavbar
    :navList="navList"
    :params="params"
    :activePoint="activePoint"
    @goToPoint="goToPoint"
  />
  <AppAddress
    :data-ob="navList[0].key"
    :qrcode="qrcode"
    @copyEvent="copyEvent"
  />
  <AppRules :data-ob="navList[1].key" />
  <AppProgress
    :data-ob="navList[2].key"
    :qrcode="qrcode"
    @copyEvent="copyEvent"
  />
  <AppAdvantage :data-ob="navList[3].key" :kefuUrl="kefu"/>
  <AppExchange
    :data-ob="navList[4].key"
    :qrcode="qrcode_ex"
    :baseUrl="baseUrl"
    @copyEvent="copyEvent"
  />
  <AppRanking :data-ob="navList[5].key" :baseUrl="baseUrl" />
  <AppQuestion :data-ob="navList[6].key" />
  <AppFooter />
  <!-- popup & modal -->
  <AppFixedbar @openModal="openModal" v-show="false" />
  <transition name="fade">
    <AppMsgPopup
      v-show="popupName === 'copySuccess'"
      :popupName="popupName"
      @close="popupOpen"
    >
      <template #content>{{ t('copied') }}</template>
    </AppMsgPopup>
  </transition>
  <AppModal
    v-if="showModal === 'qr'"
    @close="showModal = false"
    @change="showModal = false"
  >
    <template #header>扫码转账</template>
    <template #body>
      <div class="model-content">
        <QrcodeVue :value="qrcode" level="H" class="qrcode" />
        <p class="txt">必须使用【去中心化钱包】转账</p>
        <p class="code">{{ qrcode }}</p>
      </div>
    </template>
  </AppModal>
  <AppModal
    v-if="showModal === 'account'"
    @close="showModal = false"
    @change="showModal = false"
  >
    <template #header>账号转账</template>
    <template #body>
      <div class="model-content">
        <p id="copyQrcode-0" class="txt">{{ qrcode }}</p>
        <div class="c-btn" @click="copyEvent('copyQrcode-0')">一键复制</div>
        <p class="txt">必须使用【去中心化钱包】转账</p>
      </div>
    </template>
  </AppModal>
  <AppModal
    v-if="showModal === 'service'"
    @close="showModal = false"
    @change="showModal = false"
  >
    <template #header>联系客服</template>
    <template #body>
      <div class="model-content">
        <div class="contact-list">
          <div class="telegram list-item">
            <img src="@/assets/img/telegram.png" />
            <p class="txt">Telegram谘询</p>
            <p class="txt">@666sandy</p>
          </div>
          <div class="businesss list-item">
            <img src="@/assets/img/business.png" />
            <p class="txt">招商Telegram</p>
            <p class="txt">@888candy</p>
          </div>
        </div>
      </div>
    </template>
  </AppModal>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
}
:root {
  --grey: #67739f
}
#app {
  // pc
  font-family: Avenir, Helvetica, Arial, sans-serif, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 13.33333333vw;
  position: relative;
  // 1rem = 80px;
  @include mobile{
    // h5
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
a {
  color: var(--grey);
}
// modal
.model-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: var(--grey);
  .qrcode {
    margin-bottom: 1.4rem;
  }
  .txt {
    font-size: 0.8rem;
    margin-bottom: 0.4rem;
  }
  .code {
    font-size: 0.4rem;
  }
  .c-btn {
    cursor: pointer;
    height: 2rem;
    width: 100%;
    border-radius: 0.6rem;
    margin: 1.4rem 0 1rem;
    background-image: linear-gradient(to bottom, #70aef8, #4a71f1);
    line-height: 2rem;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
  }
  .contact-list {
    display: flex;
    justify-content: center;
    align-items: center;
    & > * + * {
      margin-left: 2rem;
    }
    .list-item {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      img {
        height: 4rem;
        object-fit: contain;
        margin-bottom: 0.4rem;
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}
</style>
