<script setup>
import { ref, reactive, defineProps, watch } from 'vue'
import axios from 'axios';
import QrcodeVue from 'qrcode.vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const props = defineProps({
  qrcode: String,
  baseUrl: String,
})

// init trx 100
let initValue = ref(true)
let trx = ref(100)
let usdt = ref(null)
let currentCoinType = ref('trx_to_usdt') //  else 'usdt_to_trx'

const sendCaculate = () => {
  let params = {
    symbols: currentCoinType.value,
    amount: currentCoinType.value === 'trx_to_usdt' ? trx.value : usdt.value
  }
  APICaculate(params)
}

const APICaculate = (params) => {
  axios.get(`${props.baseUrl}/calculate`, { params })
  .then((res) => {
    if (res.data.code === 200) {
      if (currentCoinType.value === 'trx_to_usdt') {
        usdt.value = String(res.data.data);
      } else {
        trx.value = String(res.data.data);
      }
      isUsdtLower.value = isTrxLower.value = false
    } else {
      if (currentCoinType.value === 'trx_to_usdt') {
        if (Number(trx.value) === 0) {
          isTrxLower.value = false
          usdt.value = ''
        } else if (Number(trx.value) < 100) {
          isTrxLower.value = true
          usdt.value = ''
        }
      } else if (currentCoinType.value === 'usdt_to_trx') {
        if (Number(usdt.value) === 0) {
          isUsdtLower.value = false
          trx.value = ''
        } else if (Number(usdt.value) < 10) {
          isUsdtLower.value = true
          trx.value = ''
        }
      }
    }
  })
  .catch((error) => {
    console.log(error);
  });
}

const handleClickInput = (type) => {
  if (initValue.value) {
    usdt.value = trx.value = ''
    initValue.value = false
  }

  if (type === 'trx') {
    isUsdtLower.value = false
  }
  if (type === 'usdt') {
    isTrxLower.value = false
  }
}
const handleBlurInput = () => {
  if (currentCoinType.value === 'trx_to_usdt') {
    if (Number(trx.value) === 0) {
      isTrxLower.value = false
      usdt.value = ''
    } else if (Number(trx.value) < 100) {
      isTrxLower.value = true
      usdt.value = ''
    }
  } else if (currentCoinType.value === 'usdt_to_trx') {
    if (Number(usdt.value) === 0) {
      isUsdtLower.value = false
      trx.value = ''
    } else if (Number(usdt.value) < 10) {
      isUsdtLower.value = true
      trx.value = ''
    }
  }
}

const isTrxLower = ref(false)
const isUsdtLower = ref(false)
const handleNumValidate = () => {
  if (currentCoinType.value === 'trx_to_usdt') {
    if (Number(trx.value) > 999_999_99) {
      trx.value = '99999999'
      return true
    }
  } else {
    if (Number(usdt.value) > 999_999_99) {
      usdt.value = '99999999'
      return true
    }
  }
}

const handleInput = (type) => {
  currentCoinType.value = type
  handleNumValidate()
  debounceInput()
}

let debounce = reactive({})
const debounceInput = () => {
  clearTimeout(debounce)
  debounce = setTimeout(() => {
    sendCaculate()
  }, 1)
}
const filterInputNum = val => {
  return val.replace(/[^\d\\'.']/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')
}
watch(() => trx.value, (val) => {
  trx.value = filterInputNum(val)
})
watch(() => usdt.value, (val) => {
  usdt.value = filterInputNum(val)
})

// trx init 100
sendCaculate()
</script>

<template>
  <div class="app-exchange" id="AppExchange">
    <div class="title-wrap">
      <div class="coins">
        <img src="@/assets/img/app-exchange/banner-img.png" />
      </div>
      <img v-if="locale === 'kr'" src="@/assets/img/app-exchange/title-kr.png" class="title" />
      <img v-else-if="locale === 'jp'" src="@/assets/img/app-exchange/title-jp.png" class="title" />
      <img v-else-if="locale === 'cn'" src="@/assets/img/app-exchange/title-cn.png" class="title" />
      <img v-else-if="locale === 'tc'" src="@/assets/img/app-exchange/title-tc.png" class="title" />
      <img v-else src="@/assets/img/app-exchange/title-en.png" class="title" />
    </div>
    <div class="rate-formula">
      <div class="rate-box">
        <span class="rf-title">{{ t(`app_exchange.current_rate`) }}</span>
        <span class="rf-desc">{{ t(`app_exchange.current_formula`) }}</span>
      </div>
    </div>
    <div class="exchange-container">
      <div class="cal-box">
        <picture>
          <source media="(min-width: 992px)"
                  srcset="@/assets/img/app-exchange/cal-bg.png" class="cal-bg"/>
          <img src="@/assets/img/app-exchange/m-cal-bg.png" class="cal-bg"/>
        </picture>
        <div :class="['cal-content', 
            { 'trx': currentCoinType === 'trx_to_usdt' },
            { 'usdt': currentCoinType === 'usdt_to_trx' }
          ]"
        >
          <div class="trx-input input-area">
            <div v-show="isUsdtLower" class="min-limit">{{ t('min_limit') }}</div>
            <input 
              v-model="trx" 
              :class="{ initValue }"
              @click="handleClickInput('trx')"
              @blur="handleBlurInput()"
              @input="handleInput('trx_to_usdt')"
            />
            <span class="unit">TRX</span>
          </div>
          <div class="txt">
            {{ t(`app_exchange.convertible`) }}
          </div>
          <div class="usdt-input input-area">
            <div v-show="isTrxLower" class="min-limit">{{ t('min_limit') }}</div>
            <input 
              v-model="usdt" 
              :class="{ initValue }"
              @click="handleClickInput('usdt')"
              @blur="handleBlurInput()"
              @input="handleInput('usdt_to_trx')"
            />
            <span class="unit">USDT</span>
          </div>
        </div>
      </div>
      <div class="cal-desc">
        <p class="desc">{{ t(`app_exchange.formula_desc`) }}</p>
        <p class="limit">{{ t(`app_exchange.limit`) }}</p>
        <div class="cal-desc-body">
          <div class="m-copy-box">
            <div class="top">
              <p class="title">{{ t('platfrom_address') }}</p>
              <div class="c-btn"  @click="$emit('copyEvent','copyQrcode-5')">{{ t('a_copy') }}</div>
            </div>
            <p class="code" id="copyQrcode-5">{{ props.qrcode }}</p>
          </div>
          <div class="qrcode-box">
            <img src="@/assets/img/app-exchange/frame.png" />
            <QrcodeVue :value="props.qrcode" level="H" :size="200" class="qrcode" />
          </div>
          <div class="tip">{{ t(`app_exchange.scan_exchange`) }}</div>
        </div>
        <div class="tag">
          <span id="copyQrcode-2">{{ props.qrcode }}</span>
        </div>
        <span class="copy-tag" @click="$emit('copyEvent','copyQrcode-2')">{{ t('a_copy') }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-exchange {
  // height: calc(1537rem / 16);
  max-width: calc(1440px - (1.5rem * 2));
  padding: 0 1.5rem;
  margin: auto;
  margin-bottom: 8rem;
  color: var(--grey);
  padding-top: 4rem;
  margin-top: -4rem;
  @include mobile{
    margin-bottom: 4rem;
    margin-top: -7.8rem;
    padding-top: 6rem;
    padding-bottom: 0.5rem;
  }
}
.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5rem;
  @include mobile{
    justify-content: center;
    margin: 1rem 0;
    padding-top: 3rem;
  }
  .coins {
    position: relative;
    width: 100%;
    height: 20rem;
    img {
      position: absolute;
      left: -16rem;
      top: 0;
      bottom: 0;
      height: 100%;
      object-fit: contain;
    }
    @include mobile{
      height: 5.8rem;
      left: 7.8rem;
      top: -6rem;
      flex: 0 0 20%;
      img {
        left: 0;
      }
    }
  }
  .title {
    margin-right: -12rem;
    object-fit: contain;
    @include mobile{
      height: 7.2rem;
      margin-right: 4rem;
    }
  }
}
.rate-formula {
  display: flex;
  margin-bottom: 2rem;
  @include mobile{
    margin-bottom: 1.5rem;
    margin-top: -1.5rem;
  }
  .rate-box {
    width: 50rem;
    font-size: 2rem;
    background: no-repeat url('~@/assets/img/app-exchange/rate-bg.png');
    background-size: 100% 100%;
    height: 6rem;
    line-height: 6rem;
    vertical-align: middle;
    text-align: center;
    padding: 0 2rem;
    margin-left: auto;
    @include mobile{
      font-size: 0.8rem;
      height: 2.2rem;
      line-height: 2.2rem;
      width: 100%;
      max-width: 28rem;
      margin: auto;
      // white-space: nowrap;
      padding: 0 0.6rem;
      margin: 0 auto;
      text-align: center;
    }
    .rf-title {
      color: #6c7ba8;
    }
    .rf-desc {
      color: #4a71f1;
      font-weight: bold;
    }
  }
}
.exchange-container {
  display: flex;
  justify-content: space-between;
  @include mobile{
    display: block;
  }
  .cal-box {
    position: relative;
    flex: 1 0 28rem;
    display: flex;
    align-items: flex-end;
    max-width: 35.5rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
    @include mobile{
      align-items: center;
      justify-content: center;
      margin: auto;
      height: 24rem;
      width: 21rem;
      max-width: 100%;
    } 
    .cal-bg {
      width: 100%;
      object-fit: contain;
      @include mobile{
        margin-top: -6rem;
        padding-top: 3rem;
      } 
    }
    .cal-content {
      position: absolute;
      bottom: 0;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      margin: auto;
      padding: 0 3rem;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: center;
      @include mobile{
        padding: 0 1.5rem;
        bottom: 14rem;
        transform: translateY(0);
        top: 0;
      }
      // &.usdt {
        // .trx-input {
        //   order: 3;
        // }
        // .usdt-input {
        //   order: 1;
        // }
      // }
      .trx-input {
        order: 1;
      }
      .usdt-input {
        order: 3;
      }
      .input-area {
        position: relative;
        width: 100%;
        height: 5rem;
        @include mobile {
          height: 2.8rem;
        }
        .min-limit {
          position: absolute;
          font-size: 2rem;
          color: #E02020;
          font-weight: bold;
          z-index: 2;
          top: 50%;
          left: 2rem;
          transform: translateY(-40%);
          @include mobile{
            font-size: 1rem;
            left: 1rem;
          }
        }
        input {
          font-size: 2.8rem;
          padding: 0 8rem 0 2rem;
          border: none;
          outline: none;
          border-radius: 2.8rem;
          position: absolute;
          height: 100%;
          width: 100%;
          background: #fff;
          box-shadow: inset 0 0.2rem 0.4rem #acacac;
          color: #3f3f3f;
          &:disabled {
            background: #f1f1f1;
            opacity: 0.7;
          }
          &.initValue {
            color: #989ca9;
          }
          @include mobile{
            font-size: 1.25rem;
            padding: 0 4rem 0 1rem;
            border-radius: 1.25rem;
            box-shadow: inset 0 0.1rem 0.2rem #acacac;
          }
        }
        .unit {
          position: absolute;
          top: 0;
          transform: translateY(30%);
          bottom: 0;
          right: 0.8rem;
          margin: auto;
          font-size: 2.4rem;
          font-weight: bold;
          @include mobile{
            font-size: 1.2rem;
          }
        }
      }
      .txt {
        order: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 2rem;
        height: 6rem;
        width: 100%;
        font-weight: bold;
        @include mobile{
          font-size: 0.9rem;
          height: 3rem;
        }
        span {
          cursor: pointer;
        }
        img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          @include mobile{
            height: 1.5rem;
          }
        }
      }
    }
  }
  .cal-desc {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;
    font-size: 1.5rem;
    @include mobile{
      font-size: 1rem;
      line-height: 1rem;
    }
    .desc {
      max-width: 30rem;
      line-height: 2rem;
      color: #2f334e;
      text-align: justify;
      @include mobile{
        max-width: 18.5rem;
        width: 100%;
        font-size: 0.86rem;
        line-height: 1.2rem;
      }
    }
    .limit {
      max-width: 30rem;
      line-height: 2rem;
      color: #4a71f1;
      font-weight: bold;
      font-size: 2rem;
      margin-top: 1rem;
      @include mobile {
        margin: 0.2rem 0 0.5rem;
        font-size: 1.2rem;
      }
    }
    .cal-desc-body {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      @include mobile {
        width: 21rem;
        max-width: 100%;
        margin: 0.8rem auto 1.5rem;
        background: no-repeat url('~@/assets/img/app-exchange/cal-body-bg.png');
        background-size: 100% 100%;
        padding: 1rem 1rem 0;
      }
    }
    .m-copy-box {
      display: none;
      @include mobile {
        margin-top: 0.5rem;
        display: block;
        .top {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-bottom: 1.2rem;
          margin-top: -0.2rem;
          .title {
            font-weight: bold;
            color: #4a71f1;
            font-size: 1.2rem;
          }
          .c-btn {
            cursor: pointer;
            height: 2rem;
            border-radius: 1rem;
            margin-left: 1rem;
            padding: 0 1.2rem;
            background-image: linear-gradient(to bottom, #70aef8, #4a71f1);
            line-height: 2rem;
            vertical-align: middle;
            text-align: center;
            color: #fff;
            font-size: 1rem;
            white-space: nowrap;
          }
        }
        .code {
          margin-top: 0.6rem;
          text-align: center;
          font-weight: bold;
          font-size: 0.8rem;
          &::selection {
            background: transparent;
          }
        }
      }
    }
    .qrcode-box {
      position: relative;
      margin: 3rem 0 1.5rem;
      @include mobile{
        height: 7rem;
        width: 7rem;
        margin: 1rem 0;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .qrcode {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 100%;
        width: 100%;
        @include mobile{
          height: 100px !important;
          width: 100px !important;
        }
      }
    }
    .tip {
      margin-bottom: 2rem;
      @include mobile{
        font-size: 0.8rem;
        color: #4a71f1;
        margin-bottom: 1rem;
        font-weight: bold;
      }
    }
    .tag {
      padding: 1.5rem;
      border-radius: 3rem;
      background-color: #F4F5F9;
      color: #343852;
      font-size: 1.1rem;
      span {
        font-weight: bold;
        &::selection {
          background: transparent;
        }
      }
      @include mobile{
        display: none;
      }
    }
    .copy-tag {
      cursor: pointer;
      height: 3rem;
      border-radius: 1.5rem;
      margin: 1.4rem 0 2rem;
      background-image: linear-gradient(to bottom, #70aef8, #4a71f1);
      line-height: 2.8rem;
      vertical-align: middle;
      text-align: center;
      color: #fff;
      font-size: 1.5rem;
      padding: 0.2rem 1.5rem;
      @include mobile{
        display: none;
      }
    }
  }
}
[data-lang=en] {
  .rate-formula {
    @include mobile{
      margin-bottom: 1.5rem;
      margin-top: -1.5rem;
    }
    .rate-box {
      @include mobile{
        font-size: 0.6rem;
      }
    }
  }
  .exchange-container {
    .cal-desc {
      .desc {
        font-size: 1.4rem;
        line-height: 1.6rem;
        @include mobile{
          font-size: 0.86rem;
          line-height: 1.2rem;
        }
      }
      .limit {
        font-size: 1.2rem;
        @include mobile {
          font-size: 0.9rem;
        }
      }
    }
    .m-copy-box {
      @include mobile {
        .top {
          .title {
            font-size: 1rem;
          }
          .c-btn {
            cursor: pointer;
            margin-left: 0.8rem;
            padding: 0 1rem;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
[data-lang=jp], [data-lang=kr] {
  .exchange-container {
    .cal-desc {
      .desc {
        font-size: 1.4rem;
        line-height: 1.6rem;
        @include mobile{
          font-size: 0.86rem;
          line-height: 1.2rem;
        }
      }
      .limit {
        font-size: 1.7rem;
        white-space: nowrap;
        @include mobile {
          font-size: 1.1rem;
        }
      }
    }
  }
}
[data-lang=kr] .title-wrap .title {
  margin-right: -4rem;
  @include mobile{
    margin-right: 4rem;
  }
}
</style>