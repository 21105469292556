<script setup>
import { ref, defineProps, watch } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
import router from "@/router/index";
import { useRoute } from "vue-router";
const route = useRoute()

const props = defineProps({
  navList: Array,
  params: Object,
  activePoint: String
})

const openNavBar = ref(false)
const langList = {
  'cn': {
    name: '中文简体'
  },
  'tc': {
    name: '中文繁體'
  },
  'en': {
    name: 'English'
  },
  'jp': {
    name: '日本语'
  },
  'kr': {
    name: '한국어'
  },
}
const currentLang = ref('')
let showLangModal = ref(false)

const handleChangeLanguage = (e) => {
  locale.value = e;
  currentLang.value = e;
  showLangModal.value = false;
  setLang()
};
const setLang = () => {
  document.querySelector(':root').dataset.lang = currentLang.value
  router.push({
    query: { ...route.query, lang: currentLang.value }
  })
}

let browserLanguage = (route.query.lang || navigator.language || navigator.userLanguage  || navigator.browserLanguage || navigator.systemLanguage).toLowerCase()

if (route.query.debug === '1') {
  alert(
    `
      queryString: ${route.query.lang},
      navigator.language: ${navigator.language},
      navigator.userLanguage: ${navigator.userLanguage},
      navigator.browserLanguage: ${navigator.browserLanguage},
      navigator.systemLanguage: ${navigator.systemLanguage}
    `
  )
}

const checkBrowserLang = () => {
  if (['ja', 'jp', 'ja-jp'].includes(browserLanguage)) {
    handleChangeLanguage('jp')
  } else if (['ko', 'kr', 'ko-kr'].includes(browserLanguage)) {
    handleChangeLanguage('kr')
  } else if (['zh-tw', 'zh-hk', 'tc', 'zh-cht'].includes(browserLanguage)) {
    handleChangeLanguage('tc')
  } else if (['zh-cn', 'zh-sg', 'zh-mo', 'cn', 'zh-chs'].includes(browserLanguage)) {
    handleChangeLanguage('cn')
  } else {
    handleChangeLanguage('en')
  }
}
checkBrowserLang()

watch(() => route.query.lang, (val, oldVal) => {
  if (val) {
    handleChangeLanguage(val)
  } else if (!val && oldVal) {
    // 變更為沒有 query 的情況
    browserLanguage = navigator.language || navigator.userLanguage  || navigator.browserLanguage || navigator.systemLanguage
    checkBrowserLang()
  }
})
</script>

<template>
  <div class="app-header">
    <div class="container">
      <img v-if="locale === 'cn'" src="@/assets/img/logo.png" class="logo" />
      <img v-else src="@/assets/img/logo-en.png" class="logo en" />
      <div :class="['nav-list', { openNavBar }]">
        <img v-if="locale === 'cn'" src="@/assets/img/logo.png" class="bar-logo" />
        <img v-else src="@/assets/img/logo-en.png" class="bar-logo en" />
        <div
          v-for="navItem in props.navList" 
          :key="navItem"
          :class="['nav-item', { active: activePoint === navItem.key}]"
          @click="$emit('goToPoint', navItem.key);openNavBar = false"
        >
          {{ t(`menu.${navItem.key}`) }}
        </div>
        <span v-show="openNavBar" class="mask" @click="openNavBar = false"></span>
      </div>
      <div class="lang-bar">
        <div class="current-lang" @click.prevent="showLangModal = !showLangModal">
          <img class="icon" :src="require(`@/assets/img/i18n/${currentLang}-icon.png`)" />
          <p class="txt">{{ langList[currentLang].name }}</p>
          <img :class="['arrow', { showLangModal }]" src="@/assets/img/i18n/down-arrow.png" />
        </div>
        <div ref="langModalRef" v-show="showLangModal" class="lang-list" @click.prevent="showLangModal = false">
          <div class="lang-content">
            <div 
              v-for="(item, key) in langList"
              :key="key" 
              :class="['lang-item', { active: currentLang === key }]"
              @click="handleChangeLanguage(key);"
            >
              <img :src="require(`@/assets/img/i18n/${key}-icon.png`)" />
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="bar" @click="openNavBar = !openNavBar">
        <img v-if="!openNavBar" src="@/assets/img/hamburger.png" />
        <img v-else src="@/assets/img/close.png" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-header {
  position: fixed;
  width: 100vw;
  background-color: #fff;
  top: 0;
  right: 0;
  left: 0;
  height: 6rem;
  z-index: 9998;
  border-bottom: 1px solid #eee;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: calc(1440px - (1.5rem * 2));
    padding: 0 1.5rem;
    margin: auto;
    height: 100%;
  }
}
.logo {
  height: 2.8rem;
  object-fit: contain;
  padding-left: 1rem;
  &.en {
    height: 2.4rem;
  }
}
.nav-list {
  flex: 1;
  max-width: 880px;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.3rem;
  color: #585d66;
  margin-left: auto;
  .bar-logo {
    display: none;
  }
  .nav-item {
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    &.active {
      color: #4a71f1;
    }
  }
}
.lang-bar {
  font-size: 1.2rem;
  position: relative;
  margin-left: 1rem;
  .current-lang {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 8.2rem;
    cursor: pointer;
    .icon {
      height: 2rem;
      margin-bottom: -0.4rem;
      object-fit: contain;
    }
    .txt {
      margin-right: auto;
    }
    .arrow {
      height: 0.6rem;
      margin-left: 0.4rem;
      object-fit: contain;
      transform: scaleY(-1);
      transition: transform .3s;
      &.showLangModal {
        transform: scaleY(1);
      }
    }
  }
  .lang-list {
    position: absolute;
    top: 2.8rem;
    right: -1.5rem;
    width: 11rem;
    padding: 0.8rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    .lang-item {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 3.2rem;
      padding-left: 0.5rem;
      &.active {
        color: #4a71f1;
      }
      img {
        height: 2rem;
        margin-bottom: -0.4rem;
        object-fit: contain;
      }
    }
  }
}
.bar {
  display: none;
  cursor: pointer;
}
@include mobile{
  .app-header {
    height: 4rem;
    z-index: 9998;
    .container {
      .logo {
        height: 2rem;
        padding-left: 0.4rem;
        &.en {
          height: 1.9rem;
        }
      }
    }
  }
  .nav-list {
    position: fixed;
    margin-top: 0;
    padding-top: 80px;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    z-index: 10000;
    flex-flow: column;
    transition: 0.3s;
    justify-content: flex-start;
    transform: translateX(100%);
    & > * + * {
      margin-left: 0;
      margin-top: 24px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: -1;
    }
    &.openNavBar {
      transform: translateX(0);
    }
    .bar-logo {
      display: block;
      height: 2rem;
      object-fit: contain;
      position: fixed;
      top: 1rem;
      left: 0;
      right: 0;
      margin: auto;
    }
    .nav-item {
      margin: 0 1rem 2.2rem;
      font-weight: bold;
      font-size: 1rem;
      padding: 6px;
      color: var(--grey);
      overflow: hidden;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
      text-align: center;
    }
  }
  .bar {
    font-size: 1rem;
    position: fixed;
    z-index: 100000;
    top: 1.3rem;
    right: 1.2rem;
    display: block;
    height: 1.2rem;
    width: 1.2rem;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .lang-bar {
    margin-right: 1.8rem;
    margin-bottom: 0.2rem;
    .current-lang {
      min-width: auto;
      .txt, .arrow {
        display: none;
      }
    }
    .lang-list {
      position: fixed;
      top: 4rem;
      right: 0;
      width: 100%;
      height: calc(100% - 4rem);
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.7);
      .lang-content {
        border-radius: 0 0 0.5rem 0.5rem;
        background-color: #fff;
        padding: 0.5rem 1.5rem;
      }
      .lang-item {
        img {
          margin-bottom: -0.2rem;
        }
      }
    }
  }
}
</style>