<script setup>
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const leftAmountLimitDesc = [
  'limit_1',
	'limit_2',
	'limit_3'
]
const RightAmountLimitDesc = [
  'limit_4',
	'limit_5'
]
const exampleWinList = [
  {
    money: '1001',
    hashId: '000*****9e3a',
    desc: 'app_rules.card.desc_1',
    formula: '1001*1.99=1991.99',
    getBonus: 'app_rules.get_bonus'
  },
  {
    money: '1005',
    hashId: '000*****9e6a',
    desc: 'app_rules.card.desc_2',
    formula: null,
    noBonus: 'app_rules.no_bonus'
  },
  {
    money: '1001',
    hashId: '000*****9e3a',
    desc: 'app_rules.card.desc_3',
    formula: '1001*1.99=1991.99',
    getBonus: 'app_rules.get_bonus'
  },
  {
    money: '220.03',
    hashId: '000*****9ec7',
    desc: 'app_rules.card.desc_4',
    formula: null,
    noBonus: 'app_rules.no_bonus'
  }
]
const handleMoneyColor = (money) => {
  let dot = money.split("").indexOf('.')
  if (dot === -1) dot = money.length
  return money.split("").map((s, idx) => idx === (dot - 1) ? `<span style="color: #5eabff">${s}</span>` : s).join('')
}
const handleHashIdColor = (hashId) => {
  const rules = /[0-9]/;
  let numArr = hashId.split("").map((s, idx) => {
    return s.match(rules) ? idx : 0
  })
  return hashId.split("").map((s, idx) => idx === Math.max(...numArr) ? `<span style="color: #5eabff">${s}</span>` : s).join('')
}
</script>

<template>
  <div class="app-rules" id="AppRules">
    <div class="container">
      <div class="main-title">
        <img v-if="locale === 'kr'" :src="require(`@/assets/img/app-rules/title-kr.png`)" />
        <img v-else-if="locale === 'jp'" :src="require(`@/assets/img/app-rules/title-jp.png`)" />
        <img v-else-if="locale === 'cn'" :src="require(`@/assets/img/app-rules/title-cn.png`)" />
        <img v-else-if="locale === 'tc'" :src="require(`@/assets/img/app-rules/title-tc.png`)" />
        <img v-else :src="require(`@/assets/img/app-rules/title-en.png`)" />
      </div>
      <div class="main-rules-list">
        <div class="rules-item">
          <div class="num">
            <span class="linear before"></span>
            01
            <span class="linear"></span>
          </div>
          <p class="rules-desc">{{ t('app_rules.rule1') }}</p>
        </div>
        <div class="rules-item">
          <div class="num">
            <span class="linear before"></span>
            02
            <span class="linear"></span>
          </div>
          <p class="rules-desc">{{ t('app_rules.rule2') }}</p>
        </div>
      </div>
      <div class="amount-limit">
        <div class="a-title">{{ t('app_rules.amount_limit') }}</div>
        <div class="limit-list">
          <div class="left">
            <p
              v-for="(item) in leftAmountLimitDesc"
              :key="item"
              class="limit-item"
            >
              {{ t(`app_rules.${item}`) }}
            </p>
          </div>
          <div class="right">
            <p
              v-for="(item) in RightAmountLimitDesc"
              :key="item"
              class="limit-item"
            >
              {{ t(`app_rules.${item}`) }}
            </p>
          </div>
        </div>
      </div>
      <div class="ex-title">{{ t('app_rules.rules_ex') }}</div>
      <ul class="ex-list">
        <li
          v-for="item in exampleWinList"
          :key="item"
          :class="['ex-item', { 'getBonus': item.getBonus }]"
        >
          <div class="transfer">
            <span class="amount">{{ t('app_rules.your_transfer_amount') }}</span>
            <span v-html="handleMoneyColor(item.money)"></span>
          </div>
          <div class="hash-id">
            <p>{{ t('app_rules.transfer_hash_value') }}</p>
            <p v-html="handleHashIdColor(item.hashId)"></p>
          </div>
          <p v-html="t(item.desc)" class="desc"></p>
          <div v-if="item.getBonus" class="result">
            <p v-html="t(item.getBonus)"></p>
            <p class="formula">{{ item.formula }}</p>
          </div>
          <div v-else class="result">
            <p v-html="t(item.noBonus)"></p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// scss mixin
@mixin greyText() {
  color: #4a5773;
}
// main style
.app-rules {
  // height: calc(1869rem / 16);
  background: no-repeat top / cover url('~@/assets/img/app-rules/rules-bg.png');
  background-size: 100% 100%;
  margin: auto;
  color: var(--grey);
  padding-top: 6rem;
  margin-top: -6rem;
  @include mobile{
    background: no-repeat top / cover url('~@/assets/img/app-rules/m-rules-bg.png');
    background-size: 100% 94%;
    background-position-y: 2rem;
    padding-top: 4rem;
    margin-top: -4rem;
    padding-bottom: 2rem;
    margin-bottom: -6.5rem;
  }
  .container {
    max-width: calc(1440px - (1.5rem * 2));
    padding: 0 1.5rem;
    margin: 0 auto 2rem;
  }
}
.main-title {
  width: 100%;
  margin: 8rem auto -2rem;
  display: flex;
  justify-content: center;
  @include mobile{
    height: 8rem;
    margin: -2rem auto -1rem;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
.main-rules-list {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  .rules-item {
    flex: 1;
    padding: 4rem;
    @include mobile{
      flex: 1 0 100%;
      padding: 1rem 1.5rem;
      max-width: 400px;
    }
    .num {
      display: flex;
      font-size: 6rem;
      font-weight: bold;
      @include greyText();
      margin-bottom: 2rem;
      @include mobile{
        font-size: 3.2rem;
        margin-bottom: 0.4rem;
      }
      .linear {
        height: 0;
        margin-top: 4rem;
        margin-left: 1.5rem;
        width: 100%;
        border-top: 0.32rem dashed #24314c;
        @include mobile{
          margin: 2rem -0.6rem 0 0.6rem;
          font-size: 2rem;
          border-top: 0.18rem dashed #24314c;
        }
        &.before {
          display: none;
          @include mobile{
            display: inline-block;
            margin: 2rem 0.6rem 0 -0.6rem;
          }
        }
      }
    }
    .rules-desc {
      font-size: 1.55rem;
      line-height: 2rem;
      text-align: justify;
      @include mobile{
        margin: 0 -0.6rem;
        font-size: 0.75rem;
        line-height: 1.3rem;
      }
    }
  }
}
.amount-limit {
  width: 100%;
  margin: 4rem 0 0;
  border-radius: 20px;
  background-image: linear-gradient(to top, #c5dbfb, #f6fbff);
  position: relative;
  z-index: 0;
  @include mobile{
    margin: 4rem 0 0;
    border-radius: 12px;
  }
  .a-title {
    position: absolute;
    top: -1.2rem;
    left: 7.2rem;
    color: #4a71f1;
    font-size: 2.4rem;
    font-weight: bold;
    @include mobile{
      font-size: 1.6rem;
      top: -1rem;
      left: 50%;
      transform: translateX(-50%);
      right: 0;
      text-align: center;
    }
  }
  .limit-list {
    display: flex;
    height: 100%;
    padding: 3rem 2rem 2rem;
    @include mobile{
      flex-flow: column;
      padding: 1.6rem 0.4rem 1rem 1rem;
    }
    .left, .right {
      flex: 0 0 calc(50% - 1rem);
    }
    .right {
      margin-left: 1rem;
      @include mobile{
        margin-left: 0;
      }
    }
    .limit-item {
      width: 100%;
      line-height: 2.2rem;
      font-size: 1.3rem;
      @include mobile{
        font-size: 0.65rem;
        line-height: 1.2rem;
      }
    }
  }
}
.ex-title {
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  margin: 6rem auto 1rem;
  @include greyText();
  @include mobile{
    font-size: 1.5rem;
    margin: 4rem auto 0;
  }
}
.ex-list {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  justify-content: space-between;
  min-width: 20rem;
  padding: 2rem 0;
  @include mobile{
    margin-right: -1.5rem;
    padding: 0;
  }
  .ex-item {
    scroll-snap-align: start;
    scroll-margin: 10px;
    background: no-repeat top / contain url('~@/assets/img/app-rules/win-box.png');
    flex: 0 0 20rem;
    height: 26rem;
    padding: 2rem;
    font-size: 1.2rem;
    margin: 1rem 0 0 1rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    line-height: 2rem;
    position: relative;
    @include mobile{
      transform: scale(0.74);
      margin: -2.1rem;
    }
    .bonus {
      font-size: 1.8rem;
      color: #3e4768;
    }
    &.getBonus {
      &::before {
        content: '';
        position: absolute;
        left: -0.7rem;
        top: -0.7rem;
        height: 6rem;
        width: 6rem;
        background: no-repeat center / contain url('~@/assets/img/app-rules/rules-tag-en.png');
      }
      .bonus {
        font-size: 1.8rem;
        color: #5eabff;
      }
    }
  }
}
// i18n
[data-lang=cn] {
  .ex-list {
    .ex-item {
      .getBonus::before {
        background: no-repeat center / contain url('~@/assets/img/app-rules/rules-tag.png');
      }
    }
  }
}
[data-lang=en], [data-lang=jp], [data-lang=kr] {
  .ex-list {
    .ex-item {
      line-height: 1.6rem;
      font-size: 1rem;
    }
  }
}
</style>
